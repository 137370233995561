<template>
  <div class="web-html">
    <div class="web-popup">
      <div class="web-title">开启课程提醒</div>
      <img src="~@/assets/images/home/official-code-prod.jpg" alt="" />
      <div class="web-text">关注公众号</div>
      <div class="web-text">课程即将开始时将微信通知您</div>
      <div id="btn" @click="btn">好的</div>
    </div>
  </div>
</template>
<script>
var wxJs, webview;
export default {
  name: 'webView',
  components: {},
  data() {
    return {};
  },
  mounted() {
    wxJs = document.createElement('script');
    wxJs.type = 'text/javascript';
    wxJs.src = 'https://res.wx.qq.com/open/js/jweixin-1.4.0.js';
    document.body.appendChild(wxJs);
    webview = document.createElement('script');
    webview.type = 'text/javascript';
    webview.src =
      'https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js';
    document.body.appendChild(webview);
    this.$nextTick(() => {
      window.addEventListener('UniAppJSBridgeReady', this.UniAppJSBridgeReady);
    });
  },
  methods: {
    btn() {
      uni.postMessage({
        data: {
          isClose: true
        }
      });
      uni.navigateBack();
    },
    UniAppJSBridgeReady() {
      console.log('加载完成，可以使用uni相关接口');
    }
  },
  beforeDestroy() {
    document.body.removeChild(wxJs);
    document.body.removeChild(webview);
  }
};
</script>
<style scoped>
.web-html {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.43);
  display: flex;
  align-items: center;
  justify-content: center;
}
.web-popup {
  width: 80%;
  min-height: 500px;
  background: #fff;
  border-radius: 20px;
  text-align: center;
  box-sizing: border-box;
  padding: 80px 60px 80px;
}
.web-title {
  font-weight: bold;
  font-size: 70px;
  margin-bottom: 28px;
  color: #00bd92;
}
img {
  width: 600px;
  height: 600px;
}
.web-text {
  font-size: 50px;
  color: #888888;
  text-align: center;
  margin: 40px auto 0px;
}
#btn {
  width: 400px;
  color: #fff;
  font-size: 55px;
  border-radius: 140px;
  height: 140px;
  line-height: 140px;
  margin: auto;
  margin-top: 120px;
  background-color: #00bd92;
}
</style>
